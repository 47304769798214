import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import Layout from "../components/layout"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulArticulos(sort: { fields: createdAt, order: DESC }) {
        edges {
          node {
            titulo
            slug
            autor
            traductor
            createdAt(formatString: "DD-MM-yyyy")
            resumen {
              raw
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      {data.allContentfulArticulos.edges.map(e => {
        return (
          <div key={e.node.slug}>
            <h2 className="post-title">
              <Link to={e.node.slug}>{e.node.titulo}</Link>
            </h2>
            <header className="post-header">
              <div className="post-author-container">
                <p className="post-author">
                  {e.node.autor &&
                    e.node.autor.map((a, i, arr) => {
                      if (arr.length > 1) {
                        return i !== arr.length - 1 ? a + ", " : " y "
                      } else {
                        return a
                      }
                    })}
                </p>
                <p className="post-date">
                  <time>{e.node.createdAt}</time>
                </p>
              </div>
            </header>

            <div className="post-body">
              {e.node.resumen && renderRichText(e.node.resumen, {})}
              <p className="post-read-more">
                <Link to={e.node.slug}>Seguir leyendo</Link>
              </p>
            </div>
          </div>
        )
      })}
    </Layout>
  )
}

export default IndexPage

export const Head = () => {
  return <title>Fuera de sí | Artículos de crítica social</title>
}
